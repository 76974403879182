const pluginName = 'toggle';

const defaultOptions = {
  className: '-dn'
};

/**
 * Toggle plugin that applies side effects to elements (hides, shows, toggles)
 * Can be extended to support multiple effects and does not cache elements on DOM load
 */
export class Toggle {
  constructor(element, options) {
    this.element = element;
    this.options = {
      ...defaultOptions,
      ...options
    };
    this.attachEventListeners();

    this.hashChangeHandler();
    if (this.options.hash && this.hashArgv !== null && this.hashArgv.includes(this.options.hash)) {
      this.currentHash = this.options.hash;
    }
  }

  attachEventListeners() {
    this.element.addEventListener('click', this.effects.bind(this));
    window.addEventListener('hashchange', this.hashChangeHandler.bind(this));
  }

  static parseHash() {
    let windowHash = window.location.hash;
    if (!windowHash) return null;
    [, windowHash] = windowHash.split('#');
    if (windowHash.includes(',')) {
      return windowHash.split(',');
    }
    return [windowHash];
  }

  hashChangeHandler() {
    if (this.skipHashHandler) {
      this.skipHashHandler = false;
      return;
    }

    this.hashArgv = Toggle.parseHash();
    if (this.hashArgv === null) return;
    this.hashArgv.forEach(hash => {
      if (hash === this.options.hash) {
        this.effects(null, true);
      }
    });
  }

  updateScopes() {
    const scopeElements = document.querySelectorAll(this.element.dataset.scope);
    if (scopeElements.length === 0) {
      this.scopes = [document];
      return;
    }
    this.scopes = scopeElements;
  }

  updateTargets() {
    let hideTargets = [];
    let showTargets = [];
    let toggleTargets = [];

    this.scopes.forEach(scope => {
      hideTargets = [
        ...hideTargets,
        ...Array.from(scope.querySelectorAll(this.element.dataset.hide))
      ];

      showTargets = [
        ...showTargets,
        ...Array.from(scope.querySelectorAll(this.element.dataset.show))
      ];

      toggleTargets = [
        ...toggleTargets,
        ...Array.from(scope.querySelectorAll(this.element.dataset.toggle))
      ];
    });

    this.hideTargets = hideTargets;
    this.showTargets = showTargets;
    this.toggleTargets = toggleTargets;
  }

  toggleHash() {
    if (!this.currentHash || this.currentHash !== this.options.hash) {
      this.skipHashHandler = true;
      window.location.hash = this.options.hash;
      this.currentHash = this.options.hash;
      return;
    }

    if (this.toggleTargets.length) {
      window.history.pushState(null, null, ' ');
      this.currentHash = null;
    }
  }

  effects(event, hashChange) {
    this.updateScopes();
    this.updateTargets();
    this.hideTargets.forEach(element => (element.classList.add(this.options.className)));
    this.showTargets.forEach(element => (element.classList.remove(this.options.className)));
    this.toggleTargets.forEach(element => (element.classList.toggle(this.options.className)));

    // the hash should be toggled only if there are toggle targets as the show/hide operations are
    // only executed once and if a hash is provided, they are already executed
    if (!hashChange && this.options.hash) {
      this.toggleHash();
    }

    const changeEvent = new Event('change');
    changeEvent.hideTargets = this.hideTargets;
    changeEvent.showTargets = this.showTargets;
    changeEvent.toggleTargets = this.toggleTargets;

    this.element.dispatchEvent(changeEvent);

    // The synthetic event below is useful in some cases when working with other plugins
    window.dispatchEvent(new Event('resize'));
  }
}

$.fn[pluginName] = function(options) {
  return this.each(function() {
    if (!$.data(this, `plugin_${pluginName}`)) {
      new Toggle(this, options);
    }
  });
};
