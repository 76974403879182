import { DataAttrParser } from '@progress-wad/data-attr-parser';
import { scrollToItem } from './scroll-to-item.js';

const defaultOptions = {
  tabButtonsSelector: ':scope > button',
  tabsContentSelector: '.TabsContent',
  tabsContentItemSelector: ':scope > .TabsContent-item',
  activeClass: 'is-open',
  activeMobileClass: 'is-mob-open',
  elementIndex: 0,
  hash: false
};

class TabsNav {
  constructor(element, options) {
    this.element = element;

    this.options = {
      ...defaultOptions,
      ...options,
      ...DataAttrParser.parse(this.element.dataset, ['tabs']).tabs
    };

    this.tabButtons = Array.from(element.querySelectorAll(this.options.tabButtonsSelector));

    // By default tabs work with the tabs content with the same index (order in the DOM)
    this.tabsContent = document.querySelectorAll(this.options.tabsContentSelector)[this.options.elementIndex];

    if (!this.tabsContent) return;

    this.tabs = this.tabsContent.querySelectorAll(this.options.tabsContentItemSelector);

    this.buttonClickHandler = this.buttonClickHandler.bind(this);
    this.mobileButtonClickHandler = this.mobileButtonClickHandler.bind(this);

    this.init();
  }

  init() {
    this.tabButtonsMobile = this.createMobileButtons();
    this.attachEventListeners();
    this.initFromHash();
  }

  attachEventListeners() {
    this.tabButtons.forEach((tabButton, index) => {
      tabButton.addEventListener('click', this.buttonClickHandler(index));
      this.tabButtonsMobile[index].addEventListener('click', this.mobileButtonClickHandler(index));
    });
  }

  /**
   * @returns {Array<HTMLButtonElement>} the mobile buttons
   */
  createMobileButtons() {
    return this.tabButtons.map((button, index) => {
      const newButton = document.createElement('button');
      newButton.setAttribute('type', 'button');
      newButton.setAttribute('data-id', button.dataset.id);
      newButton.setAttribute('class', button.getAttribute('class'));
      newButton.textContent = button.textContent;

      // Prepend the mobile tab button to the corresponding tab content
      this.tabs[index].prepend(newButton);

      return newButton;
    });
  }

  /**
   * @param {HTMLButtonElement} button 
   */
  setActiveButton(button) {
    this.tabButtons.forEach(button => {
      button.classList.remove(this.options.activeClass);
      button.removeAttribute('aria-label');
    });
    button.classList.add(this.options.activeClass);
    button.setAttribute('aria-label', 'Selected');
  }

  showTab(index) {
    this.tabs.forEach(tab => tab.classList.remove(this.options.activeClass));
    this.tabs[index].classList.add(this.options.activeClass);
  }

  toggleMobileTab(index) {
    const currentTab = this.tabs[index];
    currentTab.classList.toggle(this.options.activeMobileClass);    
   
    // scroll to the item when it opens on mobile
    if (currentTab.classList.contains(this.options.activeMobileClass)) {
      scrollToItem(currentTab);
    }
  }

  initFromHash() {
    if (!window.location.hash) return;

    const selector = window.location.hash.toString().replace('#', '');
    const button = document.querySelector(`[data-id="${selector}"]`);
    if (button) button.click();
  }

  /**
   * @param {HTMLButtonElement} button 
   */
  setHash(button) {
    if (!this.options.hash) return;

    window.location.hash = button.dataset.id;    
  }

  /**
   * @param {Number} index 
   * @returns {Function} event listener
   */
  buttonClickHandler(index) {
    return (event) => {
      event.preventDefault();

      this.setActiveButton(event.currentTarget);
      this.showTab(index);
      this.setHash(event.currentTarget);
    };
  }

  /**
   * @param {Number} index 
   * @returns {Function} event listener
   */
  mobileButtonClickHandler(index) {
    return (event) => {
      event.preventDefault();

      this.toggleMobileTab(index);
    };
  }
}

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.TabsNav').forEach((element, index) => new TabsNav(element, { elementIndex: index }));
});

window.TabsNav = TabsNav;
