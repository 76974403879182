import $ from 'jquery';

$(() => {
  const $boxes = $('.overlay-boxes');

  if ($boxes.length === 0) return;

  const $box = $('.box');
  const $close = $('.box-close');

  function close(e) {
    if ($box.not(e.target)) {
      $boxes.removeClass('is-open');
      $boxes.find('.is-active').removeClass('is-active');
      $box.removeClass('is-hidden');
    }
  }

  // expand overlay
  $box.on('click', e => {
    const deviceHeight = $(window).height();
    const boxesHeight = $boxes.height() - 30;

    if ($box.not(e.target)) {
      $boxes.removeClass('is-open');
      $boxes.find('.is-active').removeClass('is-active');
      $box.removeClass('is-hidden');
    }

    $(e.target).closest('.col-3').addClass('is-active').siblings()
      .find('.box')
      .addClass('is-hidden');

    $boxes.addClass('is-open');

    if (deviceHeight < boxesHeight && window.innerWidth < 800) {
      $('html, body').animate({
        scrollTop: $boxes.offset().top
      }, 400);
    }
  });

  // close box when clicking the X of an open box
  $close.on('click', e => {
    e.stopPropagation();
    close(e);
  });

  // close box when clicking outside boxes zone
  $(document).on('click', e => {
    if (!$(e.target).closest('.col-3').hasClass('is-active')) close(e);
  });
});
