// required class for forms
(() => {
  document.querySelectorAll('input[required], select[required], textarea[required]').forEach(function(e) {
    let prevEl = e.previousElementSibling;
    // "Country" dropdown label fix
    while (prevEl) {
      if (prevEl.tagName.toLowerCase() == 'label')  {
        prevEl.classList.add('required');
      }
      prevEl = prevEl.previousElementSibling;
    }
  });
})();

// litebox fix
(() => {
  const liteboxImageLinks = Array.from(document.querySelectorAll('a[href*=".jpg"], a[href*=".jpeg"], a[href*=".png"], a[href*=".svg"], a[href*=".gif"], .litebox-button'));
  liteboxImageLinks.forEach((link) => {
    link.classList.add('litebox');
    link.setAttribute('data-lite-info', "image");
  });
})();

// disable current link
(() => {
  document.querySelectorAll(`.-disable-current [href="${window.location.pathname}"]`).forEach(el => el.classList.add('-c-inherit', '-pen'));
})();

// <select> nav
(() => {
  let globalPartnerList = document.querySelector('.js-select-nav');
  if (globalPartnerList) {
    globalPartnerList.querySelectorAll('option').forEach(option => {
      if (window.location.pathname === option.value) option.selected = true
    });

    globalPartnerList.addEventListener('change', e => {
      window.location.pathname = e.target.value
    });
  }
})();

// detect ie
(function detectIE() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');

  if (msie > 0 || trident > 0) {
    document.body.classList.add('t-ie');
  }
})();
