(() => {
  const mobButtonNav = document.querySelector('#mobile-nav');
  const bodyClass = document.body.classList;
  const buttonLang = document.querySelector('#mob-language-button');
  const buttonSearch = document.querySelector('#mob-search-button');
  const navLang = document.querySelector('.js-nav-language');

  function toggleAriaExpanded(selector) {
    const el = document.querySelector(selector);
    if (el.getAttribute('aria-expanded') == 'false') {
      el.setAttribute('aria-expanded', 'true');
      return;
    }
    el.setAttribute('aria-expanded', 'false');
  }

  function closeAllMobileMenus() {
    bodyClass.remove('is-mobilemenu-visible', 'is-search-visible', 'is-lang-visible');
    if (mobButtonNav) mobButtonNav.setAttribute('aria-expanded', 'false');
    if (buttonLang) buttonLang.setAttribute('aria-expanded', 'false');
    if (buttonSearch) buttonSearch.setAttribute('aria-expanded', 'false');
  }

  function langClickHandler() {
    toggleAriaExpanded('#mob-language-button');
    bodyClass.remove('is-mobilemenu-visible');
    bodyClass.toggle('is-lang-visible');
    bodyClass.remove('is-search-visible');
  }

  function searchClickHandler() {
    toggleAriaExpanded('#mob-search-button');
    bodyClass.remove('is-mobilemenu-visible');
    bodyClass.remove('is-lang-visible');
    bodyClass.toggle('is-search-visible');
  }

  if (mobButtonNav) {
    mobButtonNav.addEventListener('click', () => {
      toggleAriaExpanded('#mobile-nav');
      bodyClass.toggle('is-mobilemenu-visible');
      bodyClass.remove('is-lang-visible');
      bodyClass.remove('is-search-visible');
    });
  }

  if (buttonLang) buttonLang.addEventListener('click', langClickHandler);

  if (buttonSearch) buttonSearch.addEventListener('click', searchClickHandler);

  function cloneMobileNav() {
    const mobComplementaryNav = document.querySelector('.js-mob-complementary-nav');
    if (!mobComplementaryNav) return;

    if (buttonLang) {
      const buttonLangCopy = buttonLang.cloneNode(true);
      buttonLangCopy.setAttribute('id', null);
      buttonLangCopy.addEventListener('click', langClickHandler);
      mobComplementaryNav.appendChild(buttonLangCopy);
    }

    if (buttonSearch) {
      const buttonSearchCopy = buttonSearch.cloneNode(true);
      buttonSearchCopy.setAttribute('id', null);
      buttonSearchCopy.addEventListener('click', searchClickHandler);
      mobComplementaryNav.appendChild(buttonSearchCopy);
    }

    if (navLang) {
      const navLangCopy = navLang.cloneNode(true);
      const innerEl = navLangCopy.querySelector('#language-dropdown');
      innerEl.removeAttribute('id');
      mobComplementaryNav.appendChild(navLangCopy);
    }
  }

  function getCultureDataFromDOM() {
    const map = new Map();
    const nav = document.querySelector('#js-culture-links');
  
    if (!nav) return map;
  
    const links = Array.from(nav.querySelectorAll('a'));
  
    links.forEach(link => map.set(link.dataset.culture, {
      url: link.href,
      text: link.innerText.trim(),
      active: link.classList.contains('is-active'),
      culture: link.dataset.culture
    }));
  
    return map;
  }
  
  function populateNavLocaleLinks() {
    const locales = getCultureDataFromDOM();
    const localeMenus = Array.from(document.querySelectorAll('.js-nav-language, #language-dropdown'));
  
    if (!locales.size || localeMenus.length === 0) return;
  
    localeMenus.forEach(function(localeMenu) {
      const localeLinks = Array.from(localeMenu.querySelectorAll('a'));
  
      localeLinks.forEach(function(link) {
        const { culture } = link.dataset;
        if (!locales.get(culture)) return;
    
        const { url, active } = locales.get(culture);
        link.href = url;
    
        if (active) {
          link.classList.add('is-active');
          link.setAttribute('aria-label', 'Selected Language');
        };
      });
    });
  }

  // click on modal
  const navModal = document.querySelector('.Nav-Modal');
  if (navModal) {
    navModal.addEventListener('click', ()=> {
      closeAllMobileMenus()
    });
  }

  function init() {
    cloneMobileNav();
    populateNavLocaleLinks();
  }

  // click on nav bar
  document.body.addEventListener('click', function(e) {
    if (e.target.classList.contains('Nav-Container') || e.target.classList.contains('Nav') || e.target.classList.contains('Nav-Complementary')) {
      closeAllMobileMenus();
    }
  });

  // click on body
  document.body.addEventListener('click', function(e) {
    if (document.querySelector('.PAGE-Content').contains(e.target)) {
      closeAllMobileMenus();
    }
  });

  document.addEventListener('keydown', function(e) {
    if (e.key === 'Escape') closeAllMobileMenus();
  });

  window.addEventListener('DOMContentLoaded', init);
})();
